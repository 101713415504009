<template>
<!-- 订单详情差错退款申请操作页面 -->
  <div class="page-b2b-order-detail differ-refund-container">
    <template>

    </template>
    <template>
        <div class="mod-group">
        <div class="cell-header">
          <h4>订单基础信息</h4>
        </div>
        <div class="cell-body">
          <table class="table-infomation">
            <tr>
              <th>提交人:</th>
              <td>{{ detail.submitter }}</td>
              <th>提交人账户:</th>
              <td>{{ detail.submitAccount }}</td>
              <th>联系电话:</th>
              <td>{{ detail.contact && detail.contact.contactPhone }}</td>
              <th>订单类型:</th>
              <td>{{ detail.orderType | orderType }}</td>
            </tr>
            <tr>
              <th>订单编号:</th>
              <td>{{ detail.orderNo }}</td>
              <th>订单状态:</th>
              <td>{{ detail.status | status }}</td>
              <th>成交人数:</th>
              <td>{{ detail.leftGroupQty }}</td>
              <th>拆分类型:</th>
              <td>{{
                  detail.orderId === detail.parentOrderId || !detail.parentOrderId
                    ? "主订单"
                    : "子订单"
                }}</td>
            </tr>
           <tr>
              <th>应付总金额:</th>
              <td>{{ detail.statisticInfo && detail.statisticInfo.totalOrderAmount  }}</td>
              <th>已付总金额:</th>
              <td>{{ detail.statisticInfo && detail.statisticInfo.totalActualAmount }}</td>
            </tr>
          </table>
        </div>
      </div>
      <differ-refund-payment :orderNo="detail.orderNo" @check="handleCheckPayment"></differ-refund-payment>
      <div class="mod-group">
        <div class="cell-header">
          <h4>订单基础信息</h4>
        </div>
        <div class="cell-body">
          <a-form>
            <a-row>
              <a-col span='6'>
                <a-form-item label="申请退款金额" required>
                  <a-input type="number" v-model="applyAmount"> </a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row :gutter="20">
              <a-col span="12">
                <a-form-item label="备注" required>
                  <a-input type="textarea" placeholder="输入申请退款的理由说明，例如:
押金重复支付了两笔一样的金额xxx元，申请将其中一笔xxx元进行差错退款
或：已支付票款后，订单降价，申请退款多付票款" v-model="remark"> </a-input>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="12">
                <a-form-item label="附件材料">
                  <a-upload
                    name="file"
                    :multiple="true"
                    action="/api/upload/save"
                    :headers="{
                      token
                    }"
                    :fileList="fileList"
                    @change="onUpload"
                    :remove="removeFile"
                  >
                    <a-button> <a-icon type="upload" />上传文件</a-button>
                  </a-upload>
                </a-form-item>
              </a-col>
            </a-row>
            <a-row>
              <a-col span="20">
                注意事项：
                  <br/>1、押金可作为票款的一部分，当您发生重复支付后，系统也会将支付金额当作票款的一部分，所以您在订单出票前都勿需退款
                  <br/>2、只有当您的订单超额支付后（已支付总额大于订单应付总额），才允许发起退款申请
                  <br/>3、只有当订单状态为“已出票”或“已过期”或“已作废”，才能提交退款申请
                  <br/>4、申退金额需小于支付流水剩余可退的金额，具体退款金额以后台审核为准
                  <br/>5、如您已申请过的退票、退押金、补退款、差错退款等，则需退款记录都审批完成并退款后才能继续退款
                  <br/>6、如您有需要提交的证明材料，请一并提交审核
                </a-col>
            </a-row>
          </a-form>
          <a-row>
            <a-col align="center">
              <a-button :style="{marginTop: '20px'}" type="primary" @click="handleSubmit" :loading="submitLoading">
                提交差错退款
              </a-button>
            </a-col>
          </a-row>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import queryString from 'query-string'
import { fetchOrderDetail, applyMispay } from '@/api/order'
import differRefundPayment from '../components/differ-refund-payment'

import { status, orderType } from '@/filters/order'

export default {
  name: 'differRefundApply',
  components: {
    differRefundPayment
  },
  data () {
    return {
      token: this.$ls.get('Access-Token'),
      applyAmount: 0,
      checkPay: null,
      fileList: [],
      remark: '',
      detail: {},
      submitLoading: false
    }
  },
  computed: {
    orderId () {
      return this.order && this.order.orderId
    },
    userId () {
      const userData = this.$store.state.userData
      return userData && userData.id
    }
  },
  created () {
    const params = queryString.parse(location.search)
    this.fetchDetail(params.orderNo, this.userId)
  },
  methods: {
    async fetchDetail (orderNo, userId) {
      this.loading = true

      try {
        const data = await fetchOrderDetail({
          orderNo: orderNo,
          userId
        })

        this.detail = data.bizData
        this.loading = false
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    handleCheckPayment (payment) {
      this.checkPay = payment
    },
    handleUpload () {},
    removeFile () {
      this.fileList = []
      return true
    },
    onUpload (info) {
      let { fileList } = info

      if (fileList.length) {
        fileList = fileList.map(file => {
          if (file.response) {
            const { status, data } = file.response

            if (status === 'success') {
              file.name = data.fileName
              file.filePath = data.filePath
            } else {
              file.status = 'fail'
            }
          }

          return file
        })

        this.fileList = fileList
      }
    },
    async handleSubmit () {
      if (!this.checkPay) {
        this.$message.error('请选择要申请的支付记录')
        return
      }

      if (!this.applyAmount) {
        this.$message.error('请输入正确的申退金额')
        return
      }

      if (!this.remark) {
        this.$message.error('请填写备注信息')
        return
      }

      if (!this.fileList.length && this.refundType === 'UU') {
        this.$message.error('请先上传材料')
        return
      }

      this.submitLoading = true

      const { fileList } = this
      const params =
        {
          amount: this.applyAmount,
          paymentNo: this.checkPay.tradeNo,
          remark: this.remark
        }

      params.urls = fileList.map(item => {
        return item.response.data.id
      })

      if (fileList.length) {
        params.uploadFileDTOS = fileList.map(item => {
          return {
            oriFileName: item.name,
            attachFilePath: item.filePath
          }
        })
      }

      try {
        const result = await applyMispay({
          audit: {
            action: 'START'
          },
          bizDataJson: JSON.stringify({
            orderNo: this.detail.orderNo,
            ...params
          })
        })
        const self = this

        this.$message.success('差错退款已提交申请成功，即将跳转详情', function () {
          setTimeout(() => {
            self.$router.replace({
              path: `/orderManager/differRefundDetail?misPayNo=${result}`
            })
          }, 1000)
        })

        this.submitLoading = false
      } catch (e) {
        this.submitLoading = false
        this.$message.error(e.message)
      }
    }
  },
  filters: {
    status,
    orderType
  }
}
</script>

<style lang="less">
.refund-container {
  .refund-action-row {
    margin-top: 20px;
    text-align: center;
  }
}
</style>
