<template>
  <div class="mod-group">
    <div class="cell-header">
      <h4>订单支付记录（请选择需申请差错退款的记录，提交申请）</h4>
    </div>
    <div class="cell-body">
      <a-table
        rowKey="tradeNo"
        :data-source="list"
        :columns="columns"
        :pagination="false"
        :loading="loading"
        bordered
      >
        <span slot="id" slot-scope="text, record">
          <a-radio
            :value="record.id"
            :checked="record.id === checkPay.id"
            @click="onCheckPay(record)"
          ></a-radio>
        </span>
        <span slot="index" slot-scope="text, record, index">
        {{ index + 1 }}
        </span>
        <span slot="payType" slot-scope="text">
          {{ text | bank }}
        </span>
        <span slot="payTime" slot-scope="text">
          {{ text | formatTime('YYYY-MM-DD HH:mm:ss') }}
        </span>
        <span slot="payStatus" slot-scope="text">
          {{ text | payStatus }}
        </span>
      </a-table>
      <slot></slot>
    </div>
  </div>
</template>

<script>
import { format } from '@/filters/time'
import { bank, status as payStatus } from '@/filters/pay'
import { getMispayInfo } from '@/api/order'

export default {
  data () {
    return {
      columns: [
        {
          title: '序号',
          dataIndex: 'id',
          scopedSlots: { customRender: 'id' }
        },
        {
          title: '支付流水号',
          dataIndex: 'tradeNo'
        },
        {
          title: '支付时间',
          dataIndex: 'payTime',
          scopedSlots: { customRender: 'payTime' }
        },
        {
          title: '支付金额',
          dataIndex: 'amount'
        },
        {
          title: '支付方式',
          dataIndex: 'payType',
          scopedSlots: { customRender: 'payType' }
        },
        {
          title: '状态',
          dataIndex: 'payStatus',
          scopedSlots: { customRender: 'payStatus' }
        },
        {
          title: '备注',
          dataIndex: 'remark'
        }
      ],
      checkPay: {},
      list: [],
      loading: true
    }
  },
  props: {
    orderNo: String
  },
  watch: {
    orderNo (value) {
      if (value) {
        this.fetchMispay()
      }
    }
  },
  computed: {
    checkPayId () {
      return this.checkPay.id || 0
    }
  },
  mounted () {
    this.fetchMispay()
  },
  methods: {
    async fetchMispay () {
      if (!this.orderNo) {
        return
      }
      this.loading = true
      try {
        const data = await getMispayInfo({
          orderNo: this.orderNo
        })
        this.loading = false
        this.detail = data

        if (data.misPayPaymentInfos && data.misPayPaymentInfos.length) {
          data.misPayPaymentInfos.forEach((item, index) => {
            item.id = index
          })

          this.list = data.misPayPaymentInfos || []
        }
      } catch (err) {
        this.loading = false
        this.$message.error(err.message)
      }
    },
    onMethodClick (method) {
      this.checkPayMethod = method
    },
    onCheckPay (payRow) {
      this.checkPay = payRow
      this.$emit('check', this.checkPay)
    }
  },
  filters: {
    bank,
    payStatus,
    formatTime: format
  }
}
</script>
