import { parseToFilters, parseToMap } from './util'

// 订单状态
export const status = [
  {value: "AW", label: "已提交"},
  {value: "AH", label: "审批中"},
  {value: "WP", label: "已审批"},
  {value: "PS", label: "已支付"},
  {value: "HP", label: "出票中"},
  {value: "XX", label: "已过期"},//超时自动取消
  {value: "XR", label: "拒绝"},
  {value: "XD", label: "已作废"},//用户取消
  {value: "PF", label: "已出票"},
]

export const statusMap = parseToMap(status)

// 订单状态
export const orderType = [
  {value: 'PLAN', label:  "分位订单"},
  {value: 'TEMP', label:  "临时团"},
  {value: 'AUCTION', label:  "竞拍团"}
]

export const orderTypeMap = parseToMap(orderType)

// 退票类型
export const refundType = [
  {
    label: '全部',
    value: ''
  },
  {
    label: '自愿退票',
    value: 'C'
  },
  {
    label: '非自愿退票',
    value: 'U'
  },
  {
    label: '病退',
    value: 'DISEASE'
  }
]
export const refundTypeMap = parseToMap(refundType)

// 押金申退原因
export const refundDepositType = [
  {
    label: '市场原因提价',
    value: '市场原因提价'
  },
  {
    label: '市场原因降价',
    value: '市场原因降价'
  },
  {
    label: '航班取消',
    value: '航班取消'
  },
  {
    label: '团队位被征回',
    value: '团队位被征回'
  },
  {
    label: '自愿退座',
    value: '自愿退座'
  }
]
export const refundDepositTypeMap = parseToMap(refundDepositType)

// 退票状态
export const refundStatus = [
  { label: "全部", value: '' },
  { label: "未提交", value: 'NEW' },
  { label: "已提交", value: 'SUBMIT' },
  { label: "审批中", value: 'FPASS' },
  { label: "已审批", value: 'SPASS' },
  { label: "审批拒绝", value: 'REJECT' },
  { label: "已退款", value: 'REFUND' },
  { label: "已冲抵", value: 'OFFSET' },
  { label: "退款中", value: 'RPENDING' },
  { label: "退款失败", value: 'FAIL' }
]
export const refundStatusMap = parseToMap(refundStatus)

// 退款用途
export const refundPurpose = [
  {
    label: '退款',
    value: 'REFUND'
  },
  {
    label: '冲抵',
    value: 'OFFSET'
  }
]
export const refundPurposeMap = parseToMap(refundPurpose)

// 退款类型
export const refundPaymentType = [
  {
    label: '退票退款',
    value: 'REFUND_TICKET'
  },
  {
    label: '押金退款',
    value: 'REFUND_DEPOSIT'
  },
  {
    label: '支付差错退款',
    value: 'REFUND_MISPAY'
  }
]
export const refundPaymentTypeMap = parseToMap(refundPaymentType)

// 押金退款审批状态
export const refundDepositStatus = refundStatus
export const refundDepositStatusMap = parseToMap(refundDepositStatus)

// 押金退款类型
export const refundDepositUseStatus = [
  { label: "全部", value: '' },
  { label: "退款", value: 'REFUND' },
  { label: "冲抵", value: 'OFFSET' },
]
export const refundDepositUseStatusMap = parseToMap(refundDepositUseStatus)
