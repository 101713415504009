import { 
  statusMap, 
  orderTypeMap, 
  refundTypeMap, 
  refundStatusMap,
  refundPurposeMap, 
  refundDepositTypeMap, 
  refundDepositStatusMap, 
  refundDepositUseStatusMap,
  refundPaymentTypeMap 
  } from '@/dicts/order'
import { mapFilter } from './util'

// 订单状态
export function status (value) {
  return mapFilter(value, statusMap)
}

// 订单类型
export function orderType (value) {
  return mapFilter(value, orderTypeMap)
}

// 退票类型
export function refundType (value) {
  return mapFilter(value, refundTypeMap)
}

// 退押金原因类型
export function refundDepositType (value) {
  return mapFilter(value, refundDepositTypeMap)
}

// 退票状态
export function refundStatus (value) {
  return mapFilter(value, refundStatusMap)
}

// 退款用途
export function refundPurpose (value) {
  return mapFilter(value, refundPurposeMap)
}

// 退款类型
export function refundPaymentType (value) {
  return mapFilter(value, refundPaymentTypeMap)
}

// 押金退款审批状态
export function refundDepositStatus (value) {
  return mapFilter(value, refundDepositStatusMap)
}

// 押金退款类型
export function refundDepositUseStatus (value) {
  return mapFilter(value, refundDepositUseStatusMap)
}
