import { parseToFilters, parseToMap } from './util'

// 支付类型
export const payType = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'PAY',
    label: '订单支付'
  },
  {
    value: 'REFUND',
    label: '退款'
  },
  {
    value: 'PAY_FLTCHG',
    label: '变更支付'
  }
]

export const payTypeFilters = parseToFilters(payType)

// 支付状态
export const status = [
  {
    value: '',
    label: '全部'
  },
  {
    value: 'PEND',
    label: '未付款'
  },
  {
    value: 'PAID',
    label: '已支付'
  },
  {
    value: 'REFUND',
    label: '已退款'
  },
  {
    value: 'OFFSET',
    label: '已冲抵'
  },
  {
    value: 'VOID',
    label: '已作废'
  },
  {
    value: 'REFUNDING',
    label: '退款中'
  },
  {
    value: 'UNOFFSET',
    label: '待冲抵'
  }
]

export const statusMap = parseToMap(status)

export const statusFilters = parseToFilters(status)

// 支付方式
export const bankMap = {
  ALIP: '支付宝',
  EASYPAY: '易生支付',
  ECARD: '易生支付',
  NNBILL: '99快钱',
  WECHAT: '微信',
  YEEPAY: '易宝支付',
  UNION: '银联支付',
  NEWPAY: '新生支付',
  TBAO: '淘宝支付-仅限OTA退款',
  SFYOU: '首付游支付',
  CPAY: '汇付天下',
  WALLET_EASYCARD: '福航钱包'
}
