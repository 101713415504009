import { bankMap, statusMap } from '@/dicts/pay'
import { mapFilter } from './util'

export function bank (value) {
  return mapFilter(value, bankMap)
}

export function status (value) {
  return mapFilter(value, statusMap)
}
